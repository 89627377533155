import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

const ResultPage = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const trans_no = params.get('trans');
  const reason = params.get('reason');

  useEffect(() => {
    if (code === '200') {
      navigate(`/order/detail/${trans_no}?from=payment`, {
        replace: true,
      });
    } else {
      navigate(`/message/payment-fail/${trans_no}?from=cart&reason=${reason}`, {
        replace: true,
      });
    }
  }, []);

  return <></>;
};

export default ResultPage;
